<template>
  <div>
    <!-- Titlebar -->
    <div class="parallax-container">
      <div class="parallax titlebar">
        <div id="titlebar">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2>تواصل معنا</h2>
                <span>نسعد بتواصلكم و استفساركم في اي وقت</span>

                <!-- Breadcrumbs -->
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <router-link to="/">الرئيسية</router-link>
                    </li>
                    <li>تواصل معنا</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <parallax :speed-factor="0.3" breakpoint="(min-width: 80px)">
        <img :src="titleBg" alt="Parallax Image"/>
      </parallax>
    </div>

    <!-- Container -->
    <div class="container">
      <div class="row">
        <!-- Contact Form -->
        <div class="col-md-8 col-sm-12 col-md-offset-2 col-sm-offset-0">
          <section id="contact">
            <h4 class="headline margin-bottom-35">تواصل معنا</h4>
            <p>أهلاً عزيزي، كيف يمكننا مساعدتك؟</p>

            <div id="contact-message"></div>

            <div class="my-account">
              <b-form
                id="contactform"
                method="post"
                autocomplete="on"
                @submit.prevent="onSubmit"
                class="mb-5"
              >
                <multiselect
                  v-model="communicationType"
                  :show-labels="false"
                  :class="{ 'has-error': $v.communicationType.$error }"
                  placeholder="نوع التواصل"
                  :options="communicationTypes"
                  track-by="nameEn"
                  value-field="nameEn"
                  text-field="nameAr"
                  label="nameAr"
                  class="mb-4"
                >
                  <template slot="noOptions">لا توجد عناصر</template>
                </multiselect>

                <div class="mb-4">
                  <p>
                    <label for="phone-no" class="m-0">
                      <vue-tel-input
                        v-model="phoneNumber"
                        @keydown.native.space.prevent
                        placeholder="رقم الهاتف"
                        :only-countries="['SA']"
                        default-country="SA"
                        :class="[
                          'mt-0',
                          {
                            'has-error': !isValidPhone && submitted,
                            'is-valid': isValidPhone
                          }
                        ]"
                        @validate="onValidatePhoneNumber"
                      >
                      </vue-tel-input>
                    </label>
                  </p>
                </div>

                <div class="mb-4">
                  <b-form-input
                    v-model="name"
                    placeholder="الإسم"
                    :class="{ 'has-error': $v.name.$error }"
                  >
                  </b-form-input>
                </div>

                <div>
                  <b-form-textarea
                    v-model="message"
                    cols="40"
                    rows="3"
                    placeholder="نص الرسالة"
                    spellcheck="true"
                    :class="[
                      'pr-2',
                      'pt-2',
                      { 'has-error': $v.message.$error }
                    ]"
                  ></b-form-textarea>
                </div>

                <b-button
                  id="submit"
                  variant="primary"
                  type="submit"
                  class="submit button primary"
                >
                  <span>إرسال</span>
                  <b-spinner v-show="loading" small class="mr-2"></b-spinner>
                </b-button>
              </b-form>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- Container / End -->

    <div class="margin-top-55"></div>
  </div>
</template>

<script>
import Parallax from 'vue-parallaxy'
import ResizeMixin from '@/mixins/ResizeHandler'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { required } from 'vuelidate/lib/validators'
import { userService } from '../../services'
import { VueTelInput } from 'vue-tel-input'
import { mapState } from 'vuex'

export default {
  name: 'Contact',
  layout: 'main',
  components: {
    Parallax,
    Multiselect,
    VueTelInput
  },
  mixins: [ResizeMixin],

  data() {
    return {
      loading: false,
      communicationType: '',
      message: '',
      phoneNumber: '',
      name: '',
      phoneData: {},
      communicationTypes: [
        {
          nameEn: 'message',
          nameAr: 'رسالة'
        },
        {
          nameEn: 'complaint',
          nameAr: 'شكوى'
        },
        {
          nameEn: 'suggestion',
          nameAr: 'إقتراح'
        },
        {
          nameEn: 'contactSales',
          nameAr: 'تواصل مع المبيعات'
        }
      ],
      titleBg: require('../../assets/images/home-parallax-2.jpg'),
      submitted: false
    }
  },
  validations: {
    communicationType: {
      required
    },
    message: {
      required
    },
    name: {
      required
    },
    form: ['communicationType', 'message', 'name']
  },
  computed: {
    ...mapState('accountState', ['user']),
    isValidPhone() {
      return this.phoneData.valid ? this.phoneData.valid : false
    },
    dataToSend() {
      return {
        communicationType: this.communicationType?.nameEn,
        phoneNumber: this.phoneNumber?.replace(/\s/g, '')?.replace('0', '966'),
        name: this.name,
        message: this.message
      }
    }
  },

  methods: {
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    setLoadingAndSubmitted(value) {
      this.loading = value
      this.submitted = value
    },
    async onSubmit() {
      this.setLoadingAndSubmitted(true)
      this.$v.$touch()
      if (this.$v.$invalid || !this.isValidPhone) {
        this.$toasted.show('من فضلك تأكد من ملئ جميع الحقول', {
          duration: 3000,
          type: 'error',
          icon: 'hi-close',
          singleton: true
        })
        this.loading = false
      } else {
        try {
          const { data } = await userService.contactSales(this.dataToSend)
          this.$toasted.show(
            `اهلا ومرحبا بك ${data.lead.name} ،تم استلام طلبك. سوف نقوم بالتواصل معك قريبا جدا بهذا الخصوص`,
            {
              duration: 3000,
              type: 'success',
              icon: 'hi-check'
            }
          )
          this.$v.$reset()
          this.communicationType = ''
          this.phoneNumber = ''
          this.name = ''
          this.message = ''
        } catch {
          this.$toasted.show(
            `لقد قمت بالتواصل معنا من قبل، يرجى الانتظار حتى يتم الرد عليك`,
            {
              duration: 3000,
              type: 'error',
              icon: 'hi-close'
            }
          )
        } finally {
          this.setLoadingAndSubmitted(false)
        }
      }
    }
  },
  created() {
    if (this.user) {
      this.phoneNumber = this.user.phoneNumber?.replace('966', '0')
      this.name = this.user.name
    }
  }
}
</script>

<style>
div.Masthead:after {
  background-color: rgb(61, 186, 126);
  opacity: 0.9;
}
</style>
