var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"parallax-container"},[_c('div',{staticClass:"parallax titlebar"},[_c('div',{attrs:{"id":"titlebar"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v("تواصل معنا")]),_c('span',[_vm._v("نسعد بتواصلكم و استفساركم في اي وقت")]),_c('nav',{attrs:{"id":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("الرئيسية")])],1),_c('li',[_vm._v("تواصل معنا")])])])])])])])]),_c('parallax',{attrs:{"speed-factor":0.3,"breakpoint":"(min-width: 80px)"}},[_c('img',{attrs:{"src":_vm.titleBg,"alt":"Parallax Image"}})])],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 col-sm-12 col-md-offset-2 col-sm-offset-0"},[_c('section',{attrs:{"id":"contact"}},[_c('h4',{staticClass:"headline margin-bottom-35"},[_vm._v("تواصل معنا")]),_c('p',[_vm._v("أهلاً عزيزي، كيف يمكننا مساعدتك؟")]),_c('div',{attrs:{"id":"contact-message"}}),_c('div',{staticClass:"my-account"},[_c('b-form',{staticClass:"mb-5",attrs:{"id":"contactform","method":"post","autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('multiselect',{staticClass:"mb-4",class:{ 'has-error': _vm.$v.communicationType.$error },attrs:{"show-labels":false,"placeholder":"نوع التواصل","options":_vm.communicationTypes,"track-by":"nameEn","value-field":"nameEn","text-field":"nameAr","label":"nameAr"},model:{value:(_vm.communicationType),callback:function ($$v) {_vm.communicationType=$$v},expression:"communicationType"}},[_c('template',{slot:"noOptions"},[_vm._v("لا توجد عناصر")])],2),_c('div',{staticClass:"mb-4"},[_c('p',[_c('label',{staticClass:"m-0",attrs:{"for":"phone-no"}},[_c('vue-tel-input',{class:[
                        'mt-0',
                        {
                          'has-error': !_vm.isValidPhone && _vm.submitted,
                          'is-valid': _vm.isValidPhone
                        }
                      ],attrs:{"placeholder":"رقم الهاتف","only-countries":['SA'],"default-country":"SA"},on:{"validate":_vm.onValidatePhoneNumber},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)])]),_c('div',{staticClass:"mb-4"},[_c('b-form-input',{class:{ 'has-error': _vm.$v.name.$error },attrs:{"placeholder":"الإسم"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',[_c('b-form-textarea',{class:[
                    'pr-2',
                    'pt-2',
                    { 'has-error': _vm.$v.message.$error }
                  ],attrs:{"cols":"40","rows":"3","placeholder":"نص الرسالة","spellcheck":"true"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('b-button',{staticClass:"submit button primary",attrs:{"id":"submit","variant":"primary","type":"submit"}},[_c('span',[_vm._v("إرسال")]),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mr-2",attrs:{"small":""}})],1)],1)],1)])])])]),_c('div',{staticClass:"margin-top-55"})])}
var staticRenderFns = []

export { render, staticRenderFns }